import { useQuery } from 'react-query';
import axios from 'axios';

const configureQueryString = (cityIds: string[], carTypeIds: string[], serviceIds: string[]) => {
    let queryString = '*[_type == "salon"';
    cityIds.forEach((value) => {
        queryString = queryString.concat(`&& city->._id == "${value}"`);
    });
    carTypeIds.forEach((value) => {
        queryString = queryString.concat(`&& "${value}" in autofirms[]->._id`);
    });
    serviceIds.forEach((value) => {
        queryString = queryString.concat(`&& "${value}" in services[].type->._id`);
    });

    return queryString.concat(`]{
        "id":_id,
        "address": address + ", " + city->.title, 
        "image": salonImage,
        name,
        city
}`);
};

export interface ISalonItem {
    id: string;
    name: string;
    address: string;
    image: string;
}

interface ISalonResult {
    result: ISalonItem[];
}

export const useSearchSalons = (cityIds: string[], carTypeIds: string[], serviceIds: string[]) => {
    const SALONS_SEARCH_QUERY = encodeURIComponent(configureQueryString(cityIds, carTypeIds, serviceIds));
    const PROJECT_ID = 'obfsgn0y';
    const DATASET = 'production';
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${SALONS_SEARCH_QUERY}`;
    return useQuery([cityIds, carTypeIds, serviceIds], async () => {
        const response = await axios.get<ISalonResult>(URL);
        return response.data.result;
    });
};
