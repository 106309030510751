import React, { useEffect, useState } from 'react';

import { type TNamePartOfCar, type TNamePearl } from 'engine/events/body-events-handlers/color-change-event';
import { changeColorFn, pearlObject } from 'features/tuning/modals/edit-color-modal/utils';
import { useDetailsContext } from 'components/details-context-provider/details-context-provider';

import { type IDefaults } from 'types/tuning-model';

import Canvas from './canvas';

interface IProps {
    currentVehicle: IDefaults;
    setVehicle: React.Dispatch<any>;
    setCanvasLoaded: (canvasLoaded: boolean) => void;
    canvasLoaded: boolean;
}

export default function Engine({ currentVehicle, setVehicle, setCanvasLoaded, canvasLoaded }: IProps) {
    const [firstUse, setFirstUse] = useState(false);
    const vehicle = useDetailsContext().vehicle;

    const selectColor = (color: string, pearlObject: TNamePearl, part: TNamePartOfCar) => {
        if (vehicle.current) {
            changeColorFn(part, setVehicle, color, vehicle.current, pearlObject);
        }
    };

    useEffect(() => {
        if (canvasLoaded && !firstUse) {
            setTimeout(() => {
                if (currentVehicle?.parts)
                    Object.entries(currentVehicle?.parts).forEach((value) => {
                        const [part, color] = value;

                        selectColor(
                            color,
                            pearlObject(currentVehicle, part as TNamePartOfCar, false),
                            part as TNamePartOfCar,
                        );
                        setFirstUse(true);
                    });
            }, 1000);
        }
    }, [canvasLoaded, firstUse]);

    return (
        <Canvas
            currentVehicle={currentVehicle}
            setVehicle={setVehicle}
            cameraAutoRotate={false}
            setCanvasLoaded={setCanvasLoaded}
        />
    );
}
