import { type BridgeSendInterface } from 'components/bridge-context-provider/bridge-context-provider';

export const getUsers = async <T>(
    ids: string,
    bridgeSend: BridgeSendInterface<any>,
    token: string,
    fields: string,
): Promise<T[]> => {
    const profiles: { response: T[] } = await bridgeSend('VKWebAppCallAPIMethod', {
        method: 'users.get',
        params: {
            access_token: token,
            v: '5.131',
            user_ids: ids,
            fields,
        },
    });
    return profiles.response;
};
