import React from 'react';
import { Icon24MoreHorizontal, Icon24ShareOutline } from '@vkontakte/icons';
import dayjs from 'dayjs';
import { Avatar, Card, CardGrid, Cell, ModalRoot, PlatformProvider, Text } from '@vkontakte/vkui';

import { SplitLayoutContext } from '../../components/split-layout-provider/split-layout-provider';
import { ModalProjectShare } from '../../features/profile-projects/modals/project-share';
import { BASE_API } from '../../constants/base-api';
import { type IProfile } from '../../features/profile/profile';
import { type IProject } from '../../queries/use-get-all-projects';
import { MODAL_PROJECT_SHARE } from '../../router';
import { getProfileFullName } from '../../util/get-profile-full-name';

import 'dayjs/locale/ru';

interface CardProjectInterface {
    profile?: IProfile | null;
    item: IProject;
    index: number;
    dataImages: string[];
    dataLenght: number;
    haveCell: boolean;
    key: number;
    setPopout?: (item: IProject) => void;
}

function CardProject({ profile, item, index, dataLenght, haveCell, setPopout }: CardProjectInterface) {
    const { setModal } = React.useContext(SplitLayoutContext);

    const handleProjectShare = React.useCallback(() => {
        setModal(
            <PlatformProvider value="ios">
                <ModalRoot onClose={() => setModal(null)} activeModal={MODAL_PROJECT_SHARE}>
                    <ModalProjectShare onClose={() => setModal(null)} project={item} id={MODAL_PROJECT_SHARE} />
                </ModalRoot>
            </PlatformProvider>,
        );
    }, []);

    return (
        <CardGrid id={item.id.toString()} size="l" style={{ padding: '12px', margin: 0 }}>
            {haveCell ? (
                <Cell
                    disabled
                    style={{
                        width: '100%',
                        padding: 0,
                    }}
                    className="cellWithoutPadding"
                    before={<Avatar size={40} src={profile?.photo_100} />}
                >
                    <Text>{getProfileFullName(profile)}</Text>
                </Cell>
            ) : null}
            <Card
                style={{
                    backgroundImage: `url(${BASE_API}/${item.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    cursor: 'pointer',
                }}
            >
                <div style={{ paddingBottom: '66%' }} />
            </Card>
            <div className="cardDescriptionContainer">
                {item.datetime ? (
                    <div className="cardDescriptionDate">
                        Создано {dayjs(item.datetime).locale('ru').format('DD MMMM YYYY')}
                    </div>
                ) : null}
                <div className="cardDescriptionActions">
                    <Icon24ShareOutline
                        fill="var(--vkui--color_icon_accent)"
                        onClick={handleProjectShare}
                        style={{ cursor: 'pointer' }}
                    />
                    {setPopout && (
                        <Icon24MoreHorizontal
                            onClick={() => setPopout(item)}
                            style={{ cursor: 'pointer', color: '#99A2AD' }}
                        />
                    )}
                </div>
            </div>
            {dataLenght - 1 !== index && <div className="divider" />}
        </CardGrid>
    );
}

export default CardProject;
