import React, { useState } from 'react';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import { useQueryClient } from 'react-query';

// import { vehicleConfigs } from '../../../../engine/vehicle-configs';
import { useRelatedRim } from 'hooks/use-related-rim';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { TuningHeader } from '../tuning-header';
import { MODEL_URLS } from '../../../../constants/query-keys';
import { BASE_API, REFACTOR } from '../../../../constants/base-api';
import { CardWithTick } from '../../../../components/card-with-tick/card-with-tick';
import { CategoriesSplit } from '../../../../components/categories-split/categories-split';
import { type IModelInfo, type IModelData } from '../../../../queries/use-GLTF-urls';

import '../styles.css';
import { type ISelectItem } from '../../../../types/select-item';

export const EditRimPickRimModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const queryClient = useQueryClient();
    const { rims } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};
    const preSelectedRim = rims?.find((tire) => tire.model_id === currentValue.rim)?.mark;
    const [selected, setSelected] = useState<string | undefined>(preSelectedRim);
    const { cars } = queryClient.getQueryData<IModelData>(MODEL_URLS) || {};
    const carMarks = uniq(cars?.map((car) => car.mark));
    const categories: Array<ISelectItem<string>> = uniqBy<IModelInfo>(rims, (rim) => rim.mark)
        .filter((rim) => (REFACTOR ? true : rim.mark === currentValue.mark || !carMarks.includes(rim.mark)))
        .map((rim) => ({ value: rim.mark, label: rim.mark }));
    const { id } = currentValue;
    const findRelatedRim = useRelatedRim();

    const handleCurrentRim = React.useCallback(
        (itemRim: IModelInfo) => {
            setCurrentValue({ rim: itemRim.model_id });
        },
        [setCurrentValue],
    );

    const handleRimReset = React.useCallback(() => {
        const nativeRim = findRelatedRim(id);
        setCurrentValue({ rim: nativeRim });
    }, [currentValue]);

    return (
        <div>
            <TuningHeader promotion="rim" children="Выберите модель" onReset={handleRimReset} />
            <CategoriesSplit categories={categories} selected={selected} setSelected={setSelected}>
                {rims
                    ?.filter((rim) => rim.mark === selected)
                    ?.map((r) => {
                        return (
                            <div key={r.model_id}>
                                <CardWithTick
                                    backgroundUrl={
                                        r.image ? `${BASE_API}${r.image}` : `${BASE_API}/static/assets/images/rim.png`
                                    }
                                    header={r.model_name}
                                    onClick={() => handleCurrentRim(r)}
                                    ticked={r.model_id === currentValue.rim}
                                />
                            </div>
                        );
                    })}
            </CategoriesSplit>
        </div>
    );
};
