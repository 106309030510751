import { useQuery } from 'react-query';
import axios from 'axios';

export enum FILTER_TYPES {
    CITIES = 'cities',
    SERVICES = 'services',
    CAR_TYPES = 'car_types',
}

const configureQueryString = (filterType: FILTER_TYPES) => {
    let queryString = '';
    switch (filterType) {
        case FILTER_TYPES.CAR_TYPES:
            queryString = `"autofirms": *[_type == "autofirm"]{"id":_id,title} | order(title desc)`;
            break;
        case FILTER_TYPES.SERVICES:
            queryString = `"services": *[_type == "serviceType"]{"id":_id,title}`;
            break;
        case FILTER_TYPES.CITIES:
            queryString = `"cities": *[_type == "city"]{"id":_id,title,"country":{"_id":country->._id,"title":country->.title}}`;
            break;
        default:
            queryString = '';
    }
    return queryString;
};

export interface IFilterOption {
    id: string;
    title: string;
}

interface ICitiesResponse {
    cities: IFilterOption[];
}

interface IServicesResponse {
    services: IFilterOption[];
}

interface ICarsResponse {
    autofirms: IFilterOption[];
}

interface IRequestResponse {
    result: ICitiesResponse | ICarsResponse | IServicesResponse;
}

function isCities(response: ICitiesResponse | ICarsResponse | IServicesResponse): response is ICitiesResponse {
    return (response as ICitiesResponse).cities !== undefined;
}

function isCars(response: ICitiesResponse | ICarsResponse | IServicesResponse): response is ICarsResponse {
    return (response as ICarsResponse).autofirms !== undefined;
}

function isServices(response: ICitiesResponse | ICarsResponse | IServicesResponse): response is IServicesResponse {
    return (response as IServicesResponse).services !== undefined;
}

export const useGetFilterOptions = (filterType: FILTER_TYPES) => {
    const PROJECT_ID = 'obfsgn0y';
    const DATASET = 'production';
    const FILTERS_QUERY = encodeURIComponent(`{${configureQueryString(filterType)}}`);
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${FILTERS_QUERY}`;
    return useQuery(filterType, async () => {
        const response = await axios.get<IRequestResponse>(URL);
        const data = response.data.result;
        let result: IFilterOption[] = [];
        if (isCars(data)) {
            result = data.autofirms;
        } else if (isCities(data)) {
            result = data.cities;
        } else if (isServices(data)) {
            result = data.services;
        }
        return result;
    });
};
