import React, { useEffect, useRef, useState } from 'react';
import { type Group } from 'three';
import { useQueryClient } from 'react-query';
import {
    Panel,
    PanelHeader,
    PanelHeaderButton,
    Placeholder,
    FixedLayout,
    ScreenSpinner,
    SplitLayout,
    useModalRootContext,
} from '@vkontakte/vkui';
import { Icon24ChevronLeft } from '@vkontakte/icons';
import { useLocation, useRouter } from '@happysanta/router';

import { ParticipateContestSnackbar } from 'components/participate-contest-snackbar/participate-contest-snackbar';

import { ChangeGlassToneEvent } from 'engine/events/body-events-handlers/change-glass-tone';

import { createDefaultVehicleConfig } from 'util/create-default-vehicle-config';

import { AdButton } from './components/ad-button';
import { type IWithId } from '../../types/interfaces';
import Engine from '../../engine/components/engine';
// import { vehicleConfigs } from '../../engine/vehicle-configs';
import { useGLTFUrls } from '../../queries/use-GLTF-urls';
import { CustomHeader } from '../../components/custom-header/custom-header';
import { TuningTabbar } from '../../components/tuning-tabbar/tuning-tabbar';
import { type IVehicleConfig, type IDefaults } from '../../types/tuning-model';
import { CONTEST_WELCOME_MODAL, MODAL_CARD_SAVE, PAGE_CONTEST, PAGE_MAIN, PAGE_TUNING } from '../../router';
import { TuningModal } from './tuning-modal';
import { BgProvider } from '../../hooks/use-bg';
import { DetailsContextProvider } from '../../components/details-context-provider/details-context-provider';
import { changeColorFn } from './modals/edit-color-modal/utils';
import { SaveProjectModal } from './modals/save-project/save-project-modal';
import { type IBackground, useGetFon } from '../../queries/use-get-fon';
import { useConfigureStickerControls } from '../../hooks/use-configure-sticker-controls';
import { ViewerOptionsContextProvider } from '../../components/three-context/viewer-options-context-provider';
import {
    useAdsSnackbarLayer,
    useUsedSalonIdsLayer,
} from '../../components/advertisement-provider/advertisement-provider';
import { VehicleStateProvider } from '../../components/vehicle-state-provider/vehicle-state-provider';
import { useNotificationSnackbarLayer } from '../../components/notifications-snackbar-provider/notifications-snackbar-provider';
import { AboutContestButton } from './components/about-contest-button/about-contest-button';

// Load default vehicle from local storage (if it exists).

interface IProps extends IWithId {
    contest?: boolean;
    contestConfig?: IVehicleConfig['defaults'];
    canvasLoaded: boolean;
    setCanvasLoaded: React.Dispatch<React.SetStateAction<boolean>>;
    currentVehicle: IDefaults;
    setVehicle: React.Dispatch<Partial<IDefaults>>;
}

export const Tuning = ({
    id,
    contest,
    contestConfig,
    canvasLoaded,
    setCanvasLoaded,
    setVehicle,
    currentVehicle,
}: IProps) => {
    const { isLoading, error } = useGLTFUrls();
    const router = useRouter();
    const location = useLocation();
    const PAGE = contest ? PAGE_CONTEST : PAGE_TUNING;
    const { setUsedSalons } = useUsedSalonIdsLayer();

    const [visibleResetButton, setVisibleResetButton] = useState(false);
    const vehicleRef = useRef<Group>(null);
    const wheelsRef = useRef<Group>(null);
    const { advertisementSnackBar } = useAdsSnackbarLayer();
    const { notificationSnackbar } = useNotificationSnackbarLayer();

    const { updateModalHeight } = useModalRootContext();
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData<IBackground[]>('fons');

    useEffect(() => {
        updateModalHeight();
    }, [data]);

    const { clearDetails, addNewDetail, changeDetailColor, removeDetail, moveAndScaleSticker } =
        useConfigureStickerControls(currentVehicle, vehicleRef, setVehicle);

    const handleResetClick = React.useCallback(() => {
        const defaultConfig = createDefaultVehicleConfig(contestConfig);

        if (vehicleRef.current) {
            changeColorFn('all', setVehicle, defaultConfig.color, vehicleRef.current);
            vehicleRef.current?.dispatchEvent(
                new ChangeGlassToneEvent(vehicleRef.current, 'glass_light', contestConfig?.headLightsTone),
            );
            vehicleRef.current?.dispatchEvent(
                new ChangeGlassToneEvent(vehicleRef.current, 'glass', contestConfig?.glass_tone),
            );
        }
        clearDetails();
        setVehicle({
            glass_tone: contestConfig?.glass_tone,
            headLightsTone: contestConfig?.headLightsTone,
        });
        setVehicle(defaultConfig);
        setVisibleResetButton(false);
        setUsedSalons([]);
        if (!data) return;
        setBg(data?.[0]);
    }, [data, clearDetails, contestConfig]);

    const [bg, setBg] = useState<IBackground>();

    const { data: backgrounds, isFetchedAfterMount } = useGetFon();

    useEffect(() => {
        if (isFetchedAfterMount) {
            setBg(backgrounds?.[0]);
        }
    }, [isFetchedAfterMount]);

    useEffect(() => {
        const isContestWelcomeShown = localStorage.getItem('isContestWelcomeShown') === '1';
        if (!isContestWelcomeShown && id && contest && canvasLoaded) {
            router.pushModal(CONTEST_WELCOME_MODAL);
            localStorage.setItem('isContestWelcomeShown', '1');
        }
    }, [id, contest, canvasLoaded]);

    if (error) {
        return <Placeholder style={{ height: '100%' }}>Ошибка загрузки данных</Placeholder>;
    }

    if (isLoading) {
        return <ScreenSpinner />;
    }

    return (
        <ViewerOptionsContextProvider>
            <VehicleStateProvider vehicleState={currentVehicle} setVehicle={setVehicle}>
                <BgProvider setBg={setBg} bg={bg}>
                    <DetailsContextProvider
                        removeDetail={removeDetail}
                        addNewDetail={addNewDetail}
                        clearDetails={clearDetails}
                        changeDetailColor={changeDetailColor}
                        vehicle={vehicleRef}
                        wheelsRef={wheelsRef}
                        moveAndScaleSticker={moveAndScaleSticker}
                        setVisibleResetButton={setVisibleResetButton}
                    >
                        <SplitLayout
                            modal={
                                location.getModalId() === MODAL_CARD_SAVE ? (
                                    <SaveProjectModal
                                        currentValue={currentVehicle}
                                        setCurrentValue={setVehicle}
                                        page={PAGE}
                                        contest={contest}
                                    />
                                ) : (
                                    <TuningModal contestConfig={contestConfig} />
                                )
                            }
                        >
                            <Panel id={id}>
                                <PanelHeader
                                    style={{
                                        textAlign: 'center',
                                        height: '5vh',
                                    }}
                                    before={
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Icon24ChevronLeft
                                                onClick={() => router.pushPage(PAGE_MAIN)}
                                                style={{ paddingLeft: 12 }}
                                            />
                                            {visibleResetButton ? (
                                                <PanelHeaderButton onClick={handleResetClick}>
                                                    Сбросить всё
                                                </PanelHeaderButton>
                                            ) : null}
                                        </div>
                                    }
                                    separator={false}
                                >
                                    <CustomHeader>Авто Кастомайзер</CustomHeader>
                                </PanelHeader>

                                <Engine
                                    currentVehicle={currentVehicle}
                                    setVehicle={setVehicle}
                                    setCanvasLoaded={setCanvasLoaded}
                                    canvasLoaded={canvasLoaded}
                                />
                                {advertisementSnackBar}
                                {notificationSnackbar}
                                {contest ? <AboutContestButton /> : <AdButton />}

                                <FixedLayout vertical="bottom">
                                    {!contest ? <ParticipateContestSnackbar offsetY={55} /> : null}
                                    <TuningTabbar
                                        setCurrentValue={setVehicle}
                                        canvasLoaded={canvasLoaded}
                                        hideModelAuto={contest}
                                        contest={contest}
                                    />
                                </FixedLayout>
                            </Panel>
                        </SplitLayout>
                    </DetailsContextProvider>
                </BgProvider>
            </VehicleStateProvider>
        </ViewerOptionsContextProvider>
    );
};
