import React from 'react';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { vehicleConfigs } from '../../../../engine/vehicle-configs';
import { ColorPicker } from '../../../../components/color-picker/color-picker';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { RimBrakeColorChangeEvent } from '../../../../engine/events/wheel-events-handlers/rim-brake-color-change-event-handler';
import { TuningHeader } from '../tuning-header';

export const EditRimPickSupportColorModal = () => {
    const { vehicleState: currentValue, setVehicle: setCurrentValue } = useVehicleState();
    const { wheelsRef } = useDetailsContext();

    const handleRimReset = React.useCallback(() => {
        setCurrentValue({
            rim_color_secondary: vehicleConfigs.defaults.rim_color_secondary,
        });
        if (!wheelsRef) return;
        wheelsRef.current?.dispatchEvent(
            new RimBrakeColorChangeEvent(wheelsRef.current, vehicleConfigs.defaults.rim_color_secondary),
        );
    }, [currentValue]);

    return (
        <div>
            <TuningHeader children="Выберите цвет суппортов" onReset={handleRimReset} />
            <ColorPicker
                width="100%"
                color={currentValue.rim_color_secondary}
                onChange={(color, event) => {
                    if (wheelsRef.current) {
                        setCurrentValue({ rim_color_secondary: color.hex });
                        event.stopPropagation();
                        wheelsRef?.current.dispatchEvent(new RimBrakeColorChangeEvent(wheelsRef.current, color.hex));
                    }
                }}
            />
        </div>
    );
};
