import { Avatar, Button, ButtonGroup, Caption, Div, IconButton, ModalPage, ModalPageHeader } from '@vkontakte/vkui';
import React from 'react';

import { Icon24Dismiss } from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';

import { PAGE_CONTEST } from 'router';
import { BASE_API } from 'constants/base-api';

import { useBannerData } from '../../hooks/use-banner-data';

import { type IWithId } from '../../types/interfaces';

import styles from './about-contest-modal.module.css';

interface IProps extends IWithId {
    participate?: boolean;
}

export const AboutContestModal: React.FC<IProps> = ({ id, participate = false }) => {
    const bannerData = useBannerData();
    const router = useRouter();

    const handleParticipateButton = React.useCallback(() => {
        router.pushPage(PAGE_CONTEST);
    }, []);

    const handleCloseModal = React.useCallback(() => {
        router.popPageIfModal();
    }, []);

    return (
        <ModalPage id={id} dynamicContentHeight hideCloseButton>
            <ModalPageHeader
                className={styles.header}
                after={
                    <IconButton onClick={handleCloseModal}>
                        <Icon24Dismiss />
                    </IconButton>
                }
            ></ModalPageHeader>
            <Div className={styles.content}>
                {bannerData?.public.image ? (
                    <div>
                        <Avatar src={`${BASE_API}${bannerData?.public.image}`} size={72} />
                    </div>
                ) : null}
                {bannerData?.aboutcontest_title ? (
                    <span
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: bannerData?.aboutcontest_title }}
                    />
                ) : (
                    <span></span>
                )}
                {bannerData?.aboutcontest_subhead ? (
                    <div
                        className={styles.subtitle}
                        dangerouslySetInnerHTML={{ __html: bannerData?.aboutcontest_subhead }}
                    />
                ) : (
                    <div></div>
                )}
            </Div>
            <Div>
                <ButtonGroup mode="vertical" stretched>
                    {participate ? (
                        <Button size="l" mode="primary" appearance="accent" stretched onClick={handleParticipateButton}>
                            Участвовать
                        </Button>
                    ) : null}
                    <Button
                        target="_blank"
                        href={bannerData?.public.url_public}
                        size="l"
                        mode="secondary"
                        appearance="accent"
                        stretched
                    >
                        Перейти в сообщество
                    </Button>
                </ButtonGroup>
                {bannerData?.aboutcontest_caption ? (
                    <Caption
                        className={styles.caption}
                        dangerouslySetInnerHTML={{ __html: bannerData?.aboutcontest_caption }}
                    />
                ) : null}
            </Div>
        </ModalPage>
    );
};
