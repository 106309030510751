import { useEffect, useReducer, useState } from 'react';
import { AdaptivityProvider, AppRoot, ConfigProvider, Epic, Root, View, type ViewProps } from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';
import { useLocation, useRouter } from '@happysanta/router';

import axiosInstance from 'axios-instance';

import { BASE_API } from 'constants/base-api';

import bridge from '@vkontakte/vk-bridge';

import {
    PANEL_ABOUT,
    PANEL_BATTLE_CHALLENGE,
    PANEL_BATTLE_LEADERS,
    PANEL_ERROR,
    PANEL_HELP,
    PANEL_MAIN,
    PANEL_PROFILE,
    PANEL_SALON_ITEM,
    PANEL_SALONS,
    PANEL_TUNING,
    VIEW_ABOUT,
    VIEW_BATTLE,
    VIEW_HELP,
    VIEW_MAIN,
    VIEW_PROFILE,
    VIEW_SALONS,
    VIEW_TUNING,
    VIEW_PROFILE_PROJECTS,
    PANEL_PROFILE_PROJECTS,
    VIEW_CONTEST,
    PANEL_CONTEST,
} from './router';
import { Home } from './features/home/home';
import { MyTabbar } from './components/my-tabbar/my-tabbar';
import { Salons } from './features/salons/salons/salons';
import { SalonItem } from './features/salons/salon-item/salon-item';
import { forbidTabbar } from './constants/forbid-tabbar';
import { Profile } from './features/profile/profile';
import { ProfileProjects } from './features/profile-projects';
import { Battle } from './features/battle/battle';
import { Leaders } from './features/battle/leaders';
import { Tuning } from './features/tuning/tuning';
import { Content } from './features/content/content';
import style from './features/home/home.module.css';

import './index.css';
import { AccessTokenProvider } from './components/access-token-provider/access-token-provider';
import { BridgeContextProvider } from './components/bridge-context-provider/bridge-context-provider';
import { type IVehicleConfig, type IDefaults } from './types/tuning-model';
import ErrorPanel from './features/error/error-panel';
import { useIsContest } from './queries/use-is-contest';
import { createDefaultVehicleConfig } from './util/create-default-vehicle-config';
import { AdvertisementProvider } from './components/advertisement-provider/advertisement-provider';
import { NotificationSnackbarProvider } from './components/notifications-snackbar-provider/notifications-snackbar-provider';
import SplitLayoutProvider from './components/split-layout-provider/split-layout-provider';

const App = () => {
    const location = useLocation();
    const router = useRouter();
    const [accessToken, setAccessToken] = useState(localStorage.getItem('token') ?? '');
    const panelId = location.getViewActivePanel(location.getViewId());
    const [canvasLoaded, setCanvasLoaded] = useState(false);

    const { data: isContest } = useIsContest();

    const [contestConfig, setContestConfig] = useState<IVehicleConfig['defaults']>();

    useEffect(() => {
        async function getStorage() {
            try {
                await bridge
                    .send('VKWebAppStorageGet', {
                        keys: ['token'],
                    })
                    .then((storageKeys) => {
                        const token = storageKeys.keys[0].value;
                        if (token) {
                            setAccessToken(token);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } catch (err) {
                console.error(err);
            }
        }

        async function postContestCar() {
            const res = await axiosInstance.post(`${BASE_API}/project/carcontest`);
            setContestConfig(res.data);
        }

        getStorage();
        postContestCar();
    }, []);

    // Current vehicle config.
    const [tuningVehicle, setTuningVehicle] = useReducer(
        (currentVehicle: IDefaults, newState: Partial<IDefaults>): IDefaults => ({ ...currentVehicle, ...newState }),
        createDefaultVehicleConfig(),
    );

    // Current vehicle config.
    const [contestVehicle, setContestVehicle] = useReducer(
        (currentVehicle: IDefaults, newState: Partial<IDefaults>): IDefaults => ({ ...currentVehicle, ...newState }),
        createDefaultVehicleConfig(contestConfig),
    );

    useEffect(() => {
        if (contestConfig) {
            setContestVehicle(createDefaultVehicleConfig(contestConfig));
        }
    }, [contestConfig]);

    const commonViewProps = (id: string) => {
        const props = {
            history: location.hasOverlay() ? [] : location.getViewHistory(id),
            id,
            activePanel: location.getViewActivePanel(id) as string,
            onSwipeBack: () => router.popPage(),
        };

        return props as ViewProps;
    };

    // TODO: Убрав appearance=light с небольшим кол-вом доработок мы можем поддержать темную тему

    return (
        <ConfigProvider appearance="light" hasCustomPanelHeaderAfter>
            <AdaptivityProvider>
                <AccessTokenProvider setToken={(value) => setAccessToken(value)} value={accessToken}>
                    <AdvertisementProvider>
                        <NotificationSnackbarProvider>
                            <BridgeContextProvider>
                                <AppRoot className="app_root">
                                    <SplitLayoutProvider>
                                        <Epic
                                            activeStory="root"
                                            tabbar={
                                                !forbidTabbar.includes(panelId as string) &&
                                                !location.getModalId() && <MyTabbar />
                                            }
                                        >
                                            <Root id="root" activeView={location.getViewId()}>
                                                <View {...commonViewProps(VIEW_MAIN)}>
                                                    <Home isContest={isContest} id={PANEL_MAIN} />
                                                    <ErrorPanel id={PANEL_ERROR} />
                                                </View>
                                                <View {...commonViewProps(VIEW_SALONS)}>
                                                    <Salons id={PANEL_SALONS} />
                                                    <SalonItem id={PANEL_SALON_ITEM} />
                                                </View>
                                                <View {...commonViewProps(VIEW_PROFILE)}>
                                                    <Profile id={PANEL_PROFILE} />
                                                </View>
                                                <View {...commonViewProps(VIEW_PROFILE_PROJECTS)}>
                                                    <ProfileProjects id={PANEL_PROFILE_PROJECTS} />
                                                </View>
                                                <View {...commonViewProps(VIEW_HELP)}>
                                                    <Content id={PANEL_HELP} />
                                                </View>
                                                <View {...commonViewProps(VIEW_ABOUT)}>
                                                    <Content id={PANEL_ABOUT} />
                                                </View>
                                                <View {...commonViewProps(VIEW_BATTLE)}>
                                                    <Battle id={PANEL_BATTLE_CHALLENGE} />
                                                    <Leaders id={PANEL_BATTLE_LEADERS} />
                                                </View>
                                                <View className="modalContainer" {...commonViewProps(VIEW_TUNING)}>
                                                    <div className={style.positionTop} id={PANEL_TUNING}>
                                                        <Tuning
                                                            id={PANEL_TUNING}
                                                            canvasLoaded={canvasLoaded}
                                                            setCanvasLoaded={setCanvasLoaded}
                                                            currentVehicle={tuningVehicle}
                                                            setVehicle={setTuningVehicle}
                                                        />
                                                    </div>
                                                </View>
                                                <View className="modalContainer" {...commonViewProps(VIEW_CONTEST)}>
                                                    <div id={PANEL_CONTEST}>
                                                        <Tuning
                                                            id={PANEL_CONTEST}
                                                            contest
                                                            contestConfig={createDefaultVehicleConfig(contestConfig)}
                                                            canvasLoaded={canvasLoaded}
                                                            setCanvasLoaded={setCanvasLoaded}
                                                            currentVehicle={contestVehicle}
                                                            setVehicle={setContestVehicle}
                                                        />
                                                    </div>
                                                </View>
                                            </Root>
                                        </Epic>
                                    </SplitLayoutProvider>
                                </AppRoot>
                            </BridgeContextProvider>
                        </NotificationSnackbarProvider>
                    </AdvertisementProvider>
                </AccessTokenProvider>
            </AdaptivityProvider>
        </ConfigProvider>
    );
};

export default App;
