import { vehicleConfigs } from 'engine/vehicle-configs';
import { type IVehicleConfig } from 'types/tuning-model';

export const createDefaultVehicleConfig = (contestConfig?: IVehicleConfig['defaults']) => {
    if (contestConfig) {
        const dynamicConfig = JSON.parse(contestConfig?.config || '{}') || {};
        const { color } = dynamicConfig;

        const additionalConfig = JSON.parse(contestConfig?.picture || '{}') || {};

        const { parts, picture } = additionalConfig;

        const finalConfig = {
            ...contestConfig,
            color,
            glass_tone: contestConfig.glass_tone === 'undefined' ? undefined : contestConfig.glass_tone,
            headLightsTone: contestConfig.headLightsTone === 'undefined' ? undefined : contestConfig.headLightsTone,
            picture,
            parts,
        };

        return finalConfig;
    }

    return vehicleConfigs.defaults;
};
