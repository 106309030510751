import React, { type ReactNode, useCallback, useEffect, useState } from 'react';
import {
    Avatar,
    Card,
    CardGrid,
    Cell,
    IconButton,
    Link,
    Panel,
    PanelHeader,
    ScreenSpinner,
    Snackbar,
    Spacing,
    SplitLayout,
    Text,
} from '@vkontakte/vkui';
import {
    Icon24AddSquareOutline,
    Icon24CheckCircleOn,
    Icon24ChevronRight,
    Icon28LikeCircleFillRed,
} from '@vkontakte/icons';
import { useRouter } from '@happysanta/router';

import { BATTLE_PROFILE_PROJECTS, BATTLE_WELLCOME_MODAL, PAGE_TUNING } from '../../router';
import { BattleModals } from './modals/battle-modals/battle-modals';
import { CustomHeader } from '../../components/custom-header/custom-header';
import { type IDtoPair, type IDtoResult } from '../../queries/use-get-pairs';
import { wait } from '../../util/wait';
import { useVoteForProject } from '../../queries/use-vote-for-project';
import { fetchPairs } from '../../queries/fetch-pairs';
import { type IProfile } from '../profile/profile';
import { getUsers } from '../../util/get-users';
import { getIdsStringFromList } from '../../util/get-ids-string-from-list';
import { useBridge } from '../../components/bridge-context-provider/bridge-context-provider';
import { useAccessToken } from '../../components/access-token-provider/access-token-provider';
import { getProfileFullName } from '../../util/get-profile-full-name';
import { BattlePlaceholder } from '../../components/battle-placeholder/battle-placeholder';
import { ProvideAccessPlaceholder } from '../../components/provide-access-placeholder/provide-access-placeholder';

import { APP_ID, BASE_API } from '../../constants/base-api';

import styles from './battle.module.css';
import '../../index.css';

interface IPairWithProfile extends IDtoPair {
    profile?: IProfile;
}

const mergePairsWithProfiles = (
    pairs: IDtoResult,
    profiles: IProfile[],
): Array<[IPairWithProfile, IPairWithProfile]> => {
    const result: Array<[IPairWithProfile, IPairWithProfile]> = [];

    pairs.forEach((couple) => {
        const first = couple[0];
        const second = couple[1];
        const firstProfile = profiles.find((p) => {
            return String(p.id) === String(first.person_id);
        });
        const secondProfile = profiles.find((p) => {
            return String(p.id) === String(second.person_id);
        });
        result.push([
            {
                ...first,
                profile: firstProfile,
            },
            {
                ...second,
                profile: secondProfile,
            },
        ]);
    });
    return result;
};

export const Battle = ({ id }: { id: string }) => {
    const router = useRouter();
    const { bridgeSend } = useBridge();
    const token = useAccessToken();
    const [popout, setPopout] = useState<ReactNode>(null);
    const [animation, setAnimation] = useState<string>('');
    const { mutateAsync } = useVoteForProject();
    const [data, setData] = useState<Array<[IPairWithProfile, IPairWithProfile]> | null>(null);
    const [pairIndex, setPairIndex] = useState(0);
    const [snackBar, setSnackBar] = useState<ReactNode | null>(null);
    const isAccessTokenProvided = token && token !== '';
    const isFromTuning = localStorage.getItem('isFromTuning') === 'yes';

    const shareApp = () => {
        bridgeSend('VKWebAppShare', {
            link: `https://vk.com/app${APP_ID}`,
        }).then(() => {});
    };

    useEffect(() => {
        if (isFromTuning) {
            setSnackBar(
                <Snackbar
                    style={{
                        bottom: 71,
                    }}
                    before={<Icon24CheckCircleOn fill="var(--vkui--color_icon_accent)" />}
                    onClose={() => {
                        localStorage.setItem('isFromTuning', 'no');
                        setSnackBar(null);
                    }}
                >
                    <Text>Ты теперь в баттле!</Text>
                    <Spacing size={8} />
                    <Link
                        onClick={() => {
                            shareApp();
                        }}
                    >
                        Поделиться с друзьями
                    </Link>
                </Snackbar>,
            );
        }
    }, []);

    const fetchStateData = useCallback(async () => {
        const pairs = await fetchPairs();
        const ids = getIdsStringFromList(pairs);
        const profiles = await getUsers<IProfile>(ids, bridgeSend, token, 'photo_100');
        return mergePairsWithProfiles(pairs, profiles);
    }, [token]);

    const [choosedProfile, setChoosedProfile] = React.useState<IProfile | null>(null);

    const handleProfileOpen = React.useCallback((profile: IProfile | undefined) => {
        setChoosedProfile(profile || null);
        router.pushModal(BATTLE_PROFILE_PROJECTS);
    }, []);

    const onVoteClick = useCallback(
        async (id: string) => {
            await mutateAsync(id);
            if (data && pairIndex === data.length - 1) {
                setPopout(<ScreenSpinner state="loading" />);
                const newData = await fetchStateData();
                setPopout(<ScreenSpinner state="done" aria-label="Успешно" />);
                await wait(1000);
                setPopout(null);
                setAnimation(styles.blockHidden);
                await wait(500);
                setData(newData);
                setPairIndex(0);
                await wait(500);
                setAnimation(styles.blockShown);
            } else {
                setPopout(<ScreenSpinner state="done" aria-label="Успешно" />);
                await wait(1000);
                setPopout(null);
                setAnimation(styles.blockHidden);
                await wait(500);
                setPairIndex(pairIndex + 1);
                await wait(500);
                setAnimation(styles.blockShown);
            }
        },
        [data, pairIndex],
    );

    useEffect(() => {
        if (isAccessTokenProvided) {
            const func = async () => {
                setPopout(<ScreenSpinner state="loading" />);
                const data = await fetchStateData();
                setData(data);
                setPopout(null);
            };
            func();
        }
    }, [isAccessTokenProvided]);

    useEffect(() => {
        if (localStorage.getItem('battlePopupOpened') !== 'yes' && isAccessTokenProvided && !isFromTuning) {
            router.pushModal(BATTLE_WELLCOME_MODAL);
            localStorage.setItem('battlePopupOpened', 'yes');
        }
    }, [isAccessTokenProvided, isFromTuning, isAccessTokenProvided]);

    return (
        <SplitLayout modal={<BattleModals profile={choosedProfile} />} popout={popout}>
            <Panel id={id}>
                <PanelHeader
                    style={{ textAlign: 'center' }}
                    before={
                        <Icon24AddSquareOutline
                            style={{
                                paddingLeft: 12,
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                router.pushPage(PAGE_TUNING);
                            }}
                        />
                    }
                    separator={false}
                >
                    <CustomHeader>Авто Кастомайзер</CustomHeader>
                </PanelHeader>
                {data?.length && isAccessTokenProvided && (
                    <CardGrid
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                        className={animation}
                        spaced
                        size="l"
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                            onClick={() => handleProfileOpen(data[pairIndex][0].profile)}
                        >
                            <Cell
                                disabled
                                style={{
                                    width: '100%',
                                    padding: 0,
                                }}
                                className="cellWithoutPadding"
                                before={<Avatar size={40} src={data[pairIndex][0].profile?.photo_100 || ''} />}
                            >
                                <Text>{getProfileFullName(data[pairIndex][0].profile)}</Text>
                            </Cell>
                            <IconButton onClick={() => handleProfileOpen(data[pairIndex][0].profile)}>
                                <Icon24ChevronRight fill="#B8C1CC" />
                            </IconButton>
                        </div>

                        <Card
                            style={{
                                backgroundImage: `url(${BASE_API}/${data[pairIndex][0].image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => onVoteClick(data[pairIndex][0].id)}
                        >
                            <Icon28LikeCircleFillRed height={56} width={56} className={styles.likeIcon} />
                            <div
                                style={{
                                    width: '100%',
                                    height: '27vh',
                                }}
                            />
                        </Card>
                        <Spacing size={8}></Spacing>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                width: '100%',
                            }}
                            onClick={() => handleProfileOpen(data[pairIndex][1].profile)}
                        >
                            <Cell
                                disabled
                                style={{
                                    width: '100%',
                                    padding: 0,
                                }}
                                className="cellWithoutPadding"
                                before={<Avatar size={40} src={data[pairIndex][1].profile?.photo_100 || ''} />}
                            >
                                <Text>{getProfileFullName(data[pairIndex][1].profile)}</Text>
                            </Cell>
                            <IconButton onClick={() => handleProfileOpen(data[pairIndex][1].profile)}>
                                <Icon24ChevronRight fill="#B8C1CC" />
                            </IconButton>
                        </div>
                        <Card
                            style={{
                                backgroundImage: `url(${BASE_API}/${data[pairIndex][1].image})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={() => onVoteClick(data[pairIndex][1].id)}
                        >
                            <Icon28LikeCircleFillRed height={56} width={56} className={styles.likeIcon} />
                            <div
                                style={{
                                    width: '100%',
                                    height: '27vh',
                                }}
                            />
                        </Card>
                    </CardGrid>
                )}
                {data?.length === 0 && isAccessTokenProvided && <BattlePlaceholder />}
                {!isAccessTokenProvided && <ProvideAccessPlaceholder />}
                {snackBar}
            </Panel>
        </SplitLayout>
    );
};
