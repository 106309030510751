import React from 'react';
import axios from 'axios';
import { useLocation, useRouter } from '@happysanta/router';
import { ModalPage, ModalRoot, PlatformProvider } from '@vkontakte/vkui';

import { useVehicleState } from 'components/vehicle-state-provider/vehicle-state-provider';

import { useGetSalonPromotedIds } from '../../queries/use-get-salon-promoted-ids';
import { useUsedSalonIdsLayer, useUsedService } from '../../components/advertisement-provider/advertisement-provider';
import { AD_COPYRIGHT } from '../../constants/ad-copyright';
import { useMakeCameraTransitions } from '../../engine/hooks/use-make-camera-transitions';
import { type ISalonItem } from '../../queries/use-search-salons';
import {
    ABOUT_CONTEST_MODAL,
    AD_MODAL,
    BASKET_MODAL,
    ENABLE_NOTIFICATIONS_MODAL,
    MODAL_EDIT_BODY_KIT,
    MODAL_TUNING_COLOR,
    MODAL_TUNING_ENV,
    MODAL_TUNING_GLASS_TONE,
    MODAL_TUNING_LIFT,
    MODAL_TUNING_MATERIAL,
    MODAL_TUNING_OFFSET,
    MODAL_TUNING_PICK_CAR,
    MODAL_TUNING_PICK_RIM_ACCENT,
    MODAL_TUNING_PICK_RIM_COLOR,
    MODAL_TUNING_PICK_RIM_MODEL,
    MODAL_TUNING_PICK_RIM_SIZE,
    MODAL_TUNING_PICK_STICKER,
    MODAL_TUNING_PICK_TIRE_MODEL,
    MODAL_TUNING_PICK_TIRE_SIZE,
    MODAL_TUNING_PLATE_NUMBERS,
    MODAL_TUNING_RIM_MATERIAL,
    ORDER_MODAL,
    PAGE_CONTEST,
    PAGE_TUNING,
} from '../../router';
import { AdModal } from './modals/ad-modal';
import { BasketModal } from './modals/basket-modal';
import { EditBodyKitModal } from './modals/edit-body-kit-modal/edit-body-kit-modal';
import { EditCarPickModal } from './modals/edit-car-pick-modal/edit-car-pick-modal';
import { EditColorModal } from './modals/edit-color-modal/edit-color-modal';
import { EditGlassTone } from './modals/edit-glass-tone/edit-glass-tone';
import { EditLiftModal } from './modals/edit-lift-modal/edit-lift-modal';
import { EditMaterialModal } from './modals/edit-material-modal/edit-material-modal';
import { EditOffsetModal } from './modals/edit-offset-modal/edit-offset-modal';
import { EditPickStickerModal } from './modals/edit-pick-sticker-modal/edit-pick-sticker-modal';
import { EditPlateNumberModal } from './modals/edit-plate-number-modal/edit-plate-number-modal';
import { EditRimMaterial } from './modals/edit-rim-modal/edit-rim-material';
import { EditRimPickColorModal } from './modals/edit-rim-modal/edit-rim-pick-color-modal';
import { EditRimPickRimModal } from './modals/edit-rim-modal/edit-rim-pick-rim-modal';
import { EditRimPickSizeModal } from './modals/edit-rim-modal/edit-rim-pick-size-modal';
import { EditRimPickSupportColorModal } from './modals/edit-rim-modal/edit-rim-pick-support-color-modal';
import { EditTirePickTireModal } from './modals/edit-tire-modal/edit-tire-pick-tire-modal';
import { EditTirePickTireSizeModal } from './modals/edit-tire-modal/edit-tire-pick-tire-size-modal';
import { EditTuningEnv } from './modals/edit-tuning-env/edit-tuning-env';
import { EnableNotificationsModal } from './modals/enable-notifications-modal/enable-notifications-modal';
import { OrderModal } from './modals/order-modal/order-modal';
import { type ITuningModalProps } from './modals/tuning-modal-props';

import './styles.css';
import { AboutContestModal } from '../../components/about-contest-modal/about-contest-modal';

const SALON_EXCEPTIONS = [MODAL_EDIT_BODY_KIT, MODAL_TUNING_COLOR, MODAL_TUNING_PICK_RIM_MODEL];

export function salonsQuery(type: string, currentRim: string) {
    if (type === 'rim') return currentRim;
    return type || '';
}

export const TuningModal = ({ contestConfig }: ITuningModalProps) => {
    const location = useLocation();
    const router = useRouter();
    const PAGE = contestConfig ? PAGE_CONTEST : PAGE_TUNING;
    const { vehicleState: currentValue } = useVehicleState();

    const { setUsedSalons } = useUsedSalonIdsLayer();
    const { setUsedService } = useUsedService();
    const { rollback, lookAtGlassTone } = useMakeCameraTransitions();

    const { isLoading } = useGetSalonPromotedIds();

    const handleCloseModal = React.useCallback(
        async (id: string) => {
            if (SALON_EXCEPTIONS.includes(id)) return;
            if (id === AD_MODAL || id === ABOUT_CONTEST_MODAL) return;
            const PROJECT_ID = 'obfsgn0y';
            const DATASET = 'production';
            const FILTERS_QUERY = encodeURIComponent(
                `{"model": *["${salonsQuery(
                    id,
                    currentValue.rim,
                )}" in models && _type == "salon"]{..., "id":_id, "image":salonImage}}`,
            );
            const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${FILTERS_QUERY}`;
            const response = await axios.get<{ result: { model: ISalonItem[] } }>(URL);
            const data = response.data.result;
            if (Array.isArray(data?.model)) {
                setUsedSalons(data.model);
                // @ts-expect-error ошибка так как использую строки, чтобы вытащить элементы объекта
                setUsedService(AD_COPYRIGHT[id]);
            } else {
                setUsedSalons([]);
                setUsedService(undefined);
            }
        },
        [location, router, PAGE],
    );

    const rollbackWrapper = (id: string) => {
        return () => {
            rollback();
            handleCloseModal(id);
        };
    };

    if (isLoading) {
        return null;
    }

    return (
        <PlatformProvider value="ios">
            <ModalRoot
                onClosed={(e) => {
                    handleCloseModal(e);
                }}
                activeModal={location.getModalId()}
            >
                <EnableNotificationsModal id={ENABLE_NOTIFICATIONS_MODAL} />
                <BasketModal id={BASKET_MODAL} />
                <AdModal id={AD_MODAL} />
                <ModalPage id={MODAL_TUNING_PICK_RIM_MODEL}>
                    <EditRimPickRimModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_PICK_RIM_SIZE}>
                    <EditRimPickSizeModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_PICK_RIM_COLOR}>
                    <EditRimPickColorModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_PICK_RIM_ACCENT}>
                    <EditRimPickSupportColorModal />
                </ModalPage>

                <ModalPage dynamicContentHeight id={MODAL_TUNING_PICK_TIRE_MODEL}>
                    <EditTirePickTireModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_PICK_TIRE_SIZE}>
                    <EditTirePickTireSizeModal />
                </ModalPage>

                <ModalPage dynamicContentHeight id={MODAL_TUNING_COLOR}>
                    <EditColorModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_PICK_CAR}>
                    <EditCarPickModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_OFFSET}>
                    <EditOffsetModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_LIFT}>
                    <EditLiftModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_ENV}>
                    <EditTuningEnv />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_PICK_STICKER}>
                    <EditPickStickerModal />
                </ModalPage>
                <ModalPage
                    onClosed={rollbackWrapper(MODAL_EDIT_BODY_KIT)}
                    dynamicContentHeight
                    id={MODAL_EDIT_BODY_KIT}
                >
                    <EditBodyKitModal />
                </ModalPage>
                <ModalPage
                    onOpen={lookAtGlassTone}
                    onClosed={rollbackWrapper(MODAL_TUNING_GLASS_TONE)}
                    dynamicContentHeight
                    id={MODAL_TUNING_GLASS_TONE}
                >
                    <EditGlassTone page={PAGE} />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_MATERIAL}>
                    <EditMaterialModal />
                </ModalPage>
                <ModalPage settlingHeight={100} dynamicContentHeight id={ORDER_MODAL}>
                    <OrderModal />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_RIM_MATERIAL}>
                    <EditRimMaterial />
                </ModalPage>
                <ModalPage dynamicContentHeight id={MODAL_TUNING_PLATE_NUMBERS}>
                    <EditPlateNumberModal />
                </ModalPage>
                <AboutContestModal id={ABOUT_CONTEST_MODAL} />
            </ModalRoot>
        </PlatformProvider>
    );
};
