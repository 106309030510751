import { useMemo, useRef } from 'react';
import { type Group } from 'three';

import { REFACTOR } from '../../constants/base-api';

import { WHEEL_COEF } from '../../constants/wheel-coef';

import { vehicleConfigs } from '../vehicle-configs';
import { useGetCarModel } from '../../hooks/use-get-car-model';
import { type IVehicleProps } from '../../types/vehicle-props';
import Wheels from './wheels';
import Body from './body';

// Vehicle.
const Vehicle = ({ currentVehicle }: IVehicleProps) => {
    const {
        id,
        color,
        roughness,
        lift,
        wheel_offset,
        rim,
        rim_diameter,
        rim_width,
        rim_color,
        rim_color_secondary,
        tire,
        tire_diameter,
        addons,
        tire_color,
        glass_tone,
        interiorColor,
        rim_material,
        number_plates,
        stickers,
    } = currentVehicle;

    const ref = useRef<Group>(null);

    const modelInfo = useGetCarModel(id);

    const offset = (modelInfo?.config?.wheel_offset || vehicleConfigs.vehicles.default?.wheel_offset) + wheel_offset;
    const wheelbase = modelInfo?.config?.wheelbase || vehicleConfigs.vehicles.default?.wheelbase;
    const wheelStartingPoint =
        modelInfo?.config?.wheelStartingPoint || vehicleConfigs.vehicles.default?.wheelStartingPoint || 0;
    const defaultRimDiameter = modelInfo?.config?.rim_diameter || vehicleConfigs.defaults?.rim_diameter;

    // Get wheel (axle) height.
    const axleHeight = useMemo(() => {
        const magicNumber = rim_diameter < defaultRimDiameter ? 0 : rim_diameter / 44 / 30;

        return (tire_diameter * WHEEL_COEF) / 100 / 2.5 + magicNumber;
    }, [tire_diameter, rim_diameter]);

    // Get lift height in meters.
    const liftHeight = useMemo(() => {
        const liftInches = lift || 0;

        return (liftInches * WHEEL_COEF) / 100;
    }, [lift]);

    // Get vehicle height.
    const vehicleHeight = useMemo(() => {
        return axleHeight + liftHeight;
    }, [axleHeight, liftHeight]);


    return (
        <group ref={ref} name="Vehicle">
            {!REFACTOR ? (
                <Body
                    key={id}
                    id={id}
                    height={vehicleHeight}
                    color={color}
                    roughness={roughness}
                    glass_tone={glass_tone}
                    addons={addons}
                    stickers={stickers}
                    interiorColor={interiorColor}
                    number_plates={number_plates}
                />
            ) : null}
            <Wheels
                rim={rim}
                rim_diameter={rim_diameter}
                rim_material={rim_material}
                rim_width={rim_width}
                rim_color={rim_color}
                rim_color_secondary={rim_color_secondary}
                tire={tire}
                tire_diameter={tire_diameter}
                offset={offset}
                axleHeight={axleHeight}
                wheelbase={wheelbase}
                wheelStartingPoint={wheelStartingPoint}
                color={color}
                roughness={roughness}
                tire_color={tire_color}
            />
        </group>
    );
};

export default Vehicle;
