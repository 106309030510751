import imageUrlBuilder from '@sanity/image-url';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const builder = imageUrlBuilder({ projectId: 'obfsgn0y', dataset: 'production', apiVersion: '2023-05-03' });

function urlFor(source: string) {
    return builder.image(source);
}

export const getSanityURL = (imageId?: string) => {
    if (!imageId) {
        return '';
    }

    return urlFor(imageId).toString();
};
