import { useQuery } from 'react-query';

import axios from '../axios-instance';

import { MODEL_URLS } from '../constants/query-keys';
import { BASE_API } from '../constants/base-api';
import { type IVehicle } from '../types/tuning-model';

export interface IModelInfo {
    model_name: string;
    model_id: string;
    link: string;
    mark: string;
    image: string;
}

interface ICarInfo extends IModelInfo {
    is_spoiler: boolean;
    is_obves: boolean;
    is_back_bumper: boolean;
    config: IVehicle;
    photo_icon: string;
}

interface ITireInfo extends IModelInfo {
    config?: string;
}

interface ICarDTO extends Omit<ICarInfo, 'config'> {
    config: string;
}

export interface IModelData {
    cars: ICarInfo[];
    tires: ITireInfo[];
    rims: IModelInfo[];
}

export const useGLTFUrls = () => {
    // Compose the URL for your project's endpoint and add the query
    const CARS_URL = `${BASE_API}/project/cars`;
    const TIRES_URL = `${BASE_API}/project/tires`;
    const RIMS_URL = `${BASE_API}/project/wheels`;

    // fetch the content
    return useQuery(MODEL_URLS, async (): Promise<IModelData> => {
        const { data: carsUrls } = await axios.get<ICarDTO[]>(CARS_URL);

        const { data: rimsUrls } = await axios.get<IModelInfo[]>(RIMS_URL);

        const { data: tiresUrls } = await axios.get<IModelInfo[]>(TIRES_URL);

        return {
            cars: carsUrls?.map((m) => ({
                ...m,
                config: JSON.parse(m.config) as IVehicle,
                link: `${BASE_API}` + m.link,
            })),
            rims: rimsUrls?.map((m) => ({
                ...m,
                link: `${BASE_API}` + m.link,
            })),
            tires: tiresUrls?.map((m) => ({
                ...m,
                link: `${BASE_API}` + m.link,
            })),
        };
    });
};
