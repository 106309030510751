import React from 'react';
import axios from 'axios';

import { useRouter } from '@happysanta/router';
import { ModalPageHeader, PanelHeaderButton } from '@vkontakte/vkui';

import {
    useUsedSalonIdsLayer,
    useUsedService,
} from '../../../../components/advertisement-provider/advertisement-provider';
import { CustomHeader } from '../../../../components/custom-header/custom-header';
import { useDetailsContext } from '../../../../components/details-context-provider/details-context-provider';
import { useVehicleState } from '../../../../components/vehicle-state-provider/vehicle-state-provider';
import { AD_COPYRIGHT } from '../../../../constants/ad-copyright';
import { salonsQuery } from '../../../../features/tuning/tuning-modal';
import type { ISalonItem } from '../../../../queries/use-search-salons';

interface TuningHeaderInterface {
    children: string;
    promotion?: string;
    onReset?: () => void;
}

export const TuningHeader = ({ children, promotion, onReset }: TuningHeaderInterface) => {
    const router = useRouter();
    const detailsHelper = useDetailsContext();
    const { rim } = useVehicleState();
    const { setUsedSalons } = useUsedSalonIdsLayer();
    const { setUsedService } = useUsedService();
    const currentLocation = location.hash.split('?')[0].split('/')[1];
    const PAGE = `/${currentLocation}`;

    const handleButtonClick = React.useCallback(async () => {
        if (promotion) {
            localStorage.setItem('salonFromTuning', PAGE);
            const PROJECT_ID = 'obfsgn0y';
            const DATASET = 'production';
            const FILTERS_QUERY = encodeURIComponent(
                `{"model": *["${salonsQuery(
                    promotion,
                    rim,
                )}" in models && _type == "salon"]{..., "id":_id, "image":salonImage}}`,
            );
            const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${FILTERS_QUERY}`;
            const response = await axios.get<{ result: { model: ISalonItem[] } }>(URL);
            const data = response.data.result;
            if (Array.isArray(data?.model)) {
                setUsedSalons(data.model);
                // @ts-expect-error ошибка так как использую строки, чтобы вытащить элементы объекта
                setUsedService(AD_COPYRIGHT[promotion]);
            } else {
                setUsedSalons([]);
                setUsedService(undefined);
            }
        }
        router.pushPage(PAGE);
        detailsHelper.setVisibleResetButton(true);
    }, [router, promotion, detailsHelper]);

    return (
        <ModalPageHeader
            before={onReset && <PanelHeaderButton onClick={onReset}>Сбросить</PanelHeaderButton>}
            after={<PanelHeaderButton onClick={handleButtonClick}>Готово</PanelHeaderButton>}
            style={{ textAlign: 'center' }}
        >
            <CustomHeader>{children}</CustomHeader>
        </ModalPageHeader>
    );
};
