import React from 'react';
import { Button, Placeholder } from '@vkontakte/vkui';
import { Icon56HideOutline } from '@vkontakte/icons';

import { useBridge } from 'components/bridge-context-provider/bridge-context-provider';

import { useGetProvideAccess } from '../../hooks/use-get-provide-access';
import { useAccessToken, useGetSetAccessToken } from '../access-token-provider/access-token-provider';

export const ProvideAccessPlaceholder = () => {
    const provideAccess = useGetProvideAccess();
    const setAccessToken = useGetSetAccessToken();
    const token = useAccessToken();
    const isAccessTokenProvided = token && token !== '';
    const { bridgeSend } = useBridge();

    const handleAccessButtonClick = React.useCallback(() => {
        if (!isAccessTokenProvided) {
            provideAccess().then((value) => {
                setAccessToken(value);

                bridgeSend('VKWebAppStorageSet', {
                    key: 'token',
                    value,
                });
            });
        }
    }, [provideAccess, setAccessToken]);

    return (
        <Placeholder
            action={
                <Button size="m" onClick={handleAccessButtonClick}>
                    Предоставить доступ
                </Button>
            }
            icon={<Icon56HideOutline fill="var(--vkui--color_icon_accent)" />}
            header="Раздел недоступен"
            stretched
        >
            Для этого раздела нужно дать доступ к Вашему профилю
        </Placeholder>
    );
};
