import axios from 'axios';
import { useQuery } from 'react-query';

import { type ISalonItem } from './use-search-salons';

export const useGetSalonBucket = (salonIds: string[]) => {
    const PROJECT_ID = 'obfsgn0y';
    const DATASET = 'production';
    const query = JSON.stringify(salonIds);
    const FILTERS_QUERY = encodeURIComponent(
        `*[_id in ${query} && _type == "salon"]{..., "id":_id, "image":salonImage}`,
    );
    const URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${FILTERS_QUERY}`;
    return useQuery('salon', async () => {
        const response = await axios.get<{
            result: ISalonItem[];
        }>(URL);
        const data = response.data.result;
        if (!data || !Array.isArray(data)) return [];
        return data;
    });
};
